<template>
	<div class="pagefooter">
		<div class="f_foot">
			<div class="f_logo"><img src="../pages/images/logo.png" /></div>
			<div class="f_link">
				<div class="link_lang">
					<div class="title">{{ $t("common.lang") }}</div>
					<ul>
						<li @click="changeLang_f('en_us','English','en')">English</li>
						<li @click="changeLang_f('en_us','中文','cn')">中文</li>
						<li @click="changeLang_f('en_us','繁体中文','hk')">繁体中文</li>
					</ul>
				</div>
				<div class="link_lang">
					<div class="title">{{ $t("common.web_about") }}</div>
					<ul>
						<li @click="$router.push({ path: '/Home' });">{{ $t("common.navlist_item1") }}</li>
						<li @click="$router.push({ path: '/Intro' });">{{ $t("common.navlist_item2") }}</li>
						<li @click="$router.push({ path: '/daifa' });">{{ $t("common.navlist_item3") }}</li>
						<li @click="$router.push({ path: '/news' });">{{ $t("common.navlist_item4") }}</li>
						<li @click="$router.push({ path: '/about' });">{{ $t("common.navlist_item5") }}</li>
						<li @click="$router.push({ path: '/product' });">{{ $t("common.navlist_item6") }}</li>
						<li @click="$router.push({ path: '/Mine' });">{{ $t("common.navlist_item7") }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show:true
		};
	},
	methods: {
		changeLang_f(lang,name,flagname) {
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
			localStorage.setItem("langname", name);
			localStorage.setItem("langflag", flagname);
			this.$router.push({path:'Home'});
		},
	},
	watch: {
		
	},
	created() {
		if (this.$route.name == "Login") {
			this.show = false;
		}
	},
};
</script>

<style lang="less" scoped>
.pagefooter{
	background: #000102;
	padding: 30px 0;
	.f_foot{
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.f_link{
			display: flex;
			.link_lang{
				width: 300px;
				.title{
					color: #fff;
					position: relative;
					font-size: 20px;
					padding-bottom: 10px;
					margin-bottom: 15px;
					line-height: 38px;
				}
				.title:after {
					height: 5px;
					width: 50px;
					background-color: #982123;
					content: "";
					position: absolute;
					bottom: -10px;
					left: 0;
					margin-left: 0;
				}
			}
			ul{
				li{
					padding-top:10px;
					color: #ffffff;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
