<template>
	<div class="home-container">
		<div class="banner">
			<img src="../images/banner/banner_03.jpg" style="width: 100%;" />
		</div>
		<div class="tabslist">
			<ul>
				<li @click="tabsel=1" :class="tabsel==1?'sel':''">{{ $t('news.tit_1') }}</li>
				<li @click="tabsel=2" :class="tabsel==2?'sel':''">{{ $t('news.tit_2') }}</li>
				<li @click="tabsel=3" :class="tabsel==3?'sel':''">{{ $t('news.tit_3') }}</li>
			</ul>
		</div>
		<div class="content_box">
			<div v-if="tabsel==1">
				<div class="title">Silk Road</div>
				<div style="font-size: 13px;">
					<p>{{ $t('news.con_1') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_2') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_3') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_4') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_5') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_6') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_7') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('news.con_8') }}</p>
				</div>
			</div>
			<div v-else-if="tabsel==2">
				<div class="title">Silk Road</div>
				<div style="font-size: 13px;">
					<p>{{ $t('zence.con_1') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_2') }}</p>
					<p>{{ $t('zence.con_3') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_4') }}</p>
					<p>{{ $t('zence.con_5') }}</p>
					<p>{{ $t('zence.con_6') }}</p>
					<p>{{ $t('zence.con_7') }}</p>
					<p>{{ $t('zence.con_8') }}</p>
					<p>{{ $t('zence.con_9') }}</p>
					<p>{{ $t('zence.con_10') }}</p>
					<p>{{ $t('zence.con_11') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_12') }}</p>
					<p>{{ $t('zence.con_13') }}</p>
					<p>{{ $t('zence.con_14') }}</p>
					<p>{{ $t('zence.con_15') }}</p>
					<p>{{ $t('zence.con_16') }}</p>
					<p>{{ $t('zence.con_17') }}</p>
					<p>{{ $t('zence.con_18') }}</p>
					<p>{{ $t('zence.con_19') }}</p>
					<p>{{ $t('zence.con_20') }}</p>
					<p>{{ $t('zence.con_21') }}</p>
					<p>{{ $t('zence.con_22') }}</p>
					<p>{{ $t('zence.con_23') }}</p>
					<p>{{ $t('zence.con_24') }}</p>
					<p>{{ $t('zence.con_25') }}</p>
					<p>{{ $t('zence.con_26') }}</p>
					<p>{{ $t('zence.con_27') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_28') }}</p>
					<p>{{ $t('zence.con_29') }}</p>
					<p>{{ $t('zence.con_30') }}</p>
					<p>{{ $t('zence.con_31') }}</p>
					<p>{{ $t('zence.con_32') }}</p>
					<p>{{ $t('zence.con_33') }}</p>
					<p>{{ $t('zence.con_34') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_35') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_36') }}</p>
					<p>{{ $t('zence.con_37') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_38') }}</p>
					<p>{{ $t('zence.con_39') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_40') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_41') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_42') }}</p>
					<p>{{ $t('zence.con_43') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_44') }}</p>
					<p>{{ $t('zence.con_45') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_46') }}</p>
					<p>{{ $t('zence.con_47') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_48') }}</p>
					<p>{{ $t('zence.con_49') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_50') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('zence.con_51') }}</p>
					
				</div>
			</div>
			<div v-else-if="tabsel==3">
				<div class="title">Silk Road</div>
				<div style="font-size: 13px;">
					<p>{{ $t('hwintro.con_1') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_2') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_3') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_4') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_5') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_6') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_7') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_8') }}</p>
					<p>&nbsp;</p>
					<p>{{ $t('hwintro.con_9') }}</p>
					<p>&nbsp;</p>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			keyword: '',
			tabsel:1
		};
	},
	methods: {

	},
	created() {

	}
}

</script>

<style lang='less' scoped>
.banner {
	width: 100%;
}
.tabslist{
	width: 100%;
	max-width: 1200px;
	margin: 20px auto 0;
	padding-top: 50px;
	ul{
		display: flex;
		justify-content: center;
		text-align: center;
		font-size: 20px;
		flex-wrap: wrap;
		li{
			margin: 0 10px;
			border: 2px solid #c9151e;
			color: #c9151e;
			height: 46px;
			line-height: 42px;
			border-radius: 23px;
			padding: 0 15px;
			cursor: pointer;
		}
		li.sel{
			background-color: #c9151e;
			color: #fff;
		}
	}
}

.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 20px auto 30px;
	padding: 15px;
	border:solid 1px #ccc;
	border-radius: 10px;
	.world {

		.titttt {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.titttt:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		.con {
			display: flex;
			margin-top: 50px;

			>div {
				width: 50%;
			}

			.img {
				width: 600px;

				img {
					width: 600px;
				}
			}

			.list {
				width: 100%;

				ul {
					li {
						display: flex;
						justify-content: flex-start;
						padding: 10px 30px;
						box-shadow: 1px 1px 5px #666;
						border-radius: 10px;
						margin: 20px 0;
						align-items: center;

						img {
							width: 110px;
							margin-right: 30px;
						}

						div {
							font-size: 22px;
							font-weight: 700;
							text-align: center;
						}
					}
				}
			}
		}

	}

}

.serve_box {
	.serve_con {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 50px;

		.tit {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.tit:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		ul {
			display: flex;
			justify-content: space-around;
			padding-top: 100px;
			padding-bottom: 40px;

			li {
				width: 20%;
				padding: 20px 20px;
				margin: 0 auto;
				background-color: #fff;
				border-radius: 15px;
				box-shadow: 1px 1px 3px #ccc;
				margin-bottom: 20px;
				position: relative;

				.bgimg1 {
					background: url(../images/home/conn1_12.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_22.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_32.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_42.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					position: relative;
					font-size: 24px;
					padding: 1rem 0;
					color: #000;
					text-align: left;
					font-weight: 700;
				}

				.stit:after {
					height: 3px;
					width: 60px;
					background-color: #c9151e;
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					margin-left: 0;
				}

				.des {
					padding: 1rem 0;
					font-size: 16px;
					color: #000;
					text-align: left;
				}
			}

			li:hover {
				background-color: #c9151e;
				color: #fff;

				.bgimg1 {
					background: url(../images/home/conn1_11.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_21.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_31.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_41.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					color: #fff;
				}

				.stit:after {
					background-color: #fff;

				}

				.des {
					color: #fff;
				}
			}
		}
	}
}
</style>
