<template>
	<div class="home-container">
		<div class="banner">
			<img src="../images/banner/banner_05.jpg" style="width: 100%;" />
		</div>
		<div class="content_box">
			<div class="prosearch">
				<div class="catselect">
					<div class="inputfield">
						<van-field v-model="proid" class="input" :placeholder="$t('product.proid')"></van-field>
					</div>
					<div class="inputfield" style="position: relative;">
						<van-field v-model="catid" class="input" readonly :placeholder="$t('product.classsel')" @click="active ? active = false : active = true">
							<van-icon  slot="right-icon" name="arrow-down" color="#999" size="12" />
						</van-field>
						<van-popup v-model="active" position="top">
							<div class="wrapper">
								<div class="item">
									<ul>
										<li v-for="(citem,cind) in procate" :key="cind" @click="selcate(citem)">{{ citem.name }}</li>
									</ul>
								</div>
								
							</div>
						</van-popup>
					</div>
					<div class="inputfield" style="position: relative;">
						<van-field v-model="catsid" class="input" readonly :placeholder="$t('product.classselsub')" @click="active_sub ? active_sub = false : active_sub = true">
							<van-icon  slot="right-icon" name="arrow-down" color="#999" size="12" />
						</van-field>
						<van-popup v-model="active_sub" position="top">
							<div class="wrapper">
								<div class="item">
									<ul>
										<li v-for="(citem,cind) in prosubcate" :key="cind" @click="selsubcate(citem)">{{ citem.name }}</li>
									</ul>
								</div>
								
							</div>
						</van-popup>
					</div>
					<div class="inputfield" style="position: relative;">
						<van-field v-model="pronum" class="input" readonly :placeholder="$t('product.shownum')" @click="active_num ? active_num = false : active_num = true"></van-field>
						<van-popup v-model="active_num" position="top">
							<div class="wrapper">
								<div class="item">
									<ul>
										<li @click="selpronum(10)">10</li>
										<li @click="selpronum(50)">50</li>
										<li @click="selpronum(100)">100</li>
									</ul>
								</div>
								
							</div>
						</van-popup>
					</div>
					<van-button class="login-btn" type="primary" size="normal" @click="selectpro()">{{ $t('product.piliangup') }}</van-button>
				</div>
				<div class="pagebox">
					<van-pagination v-model="page" :total-items="totalnum" :show-page-size="5" prev-text="&lt;" next-text="&gt;" force-ellipses @change="getProductList" />
				</div>
			</div>
			<div class="prolisttit">
				<van-checkbox v-model="allcheck" shape="square" icon-size="14px">{{ $t('my.tabstr2') }}</van-checkbox>
			</div>
			<div class="productlist" v-for="(item,index) in prolist" :key="index">
				<div class="chk">
					<van-checkbox v-model="item.isChk" shape="square" icon-size="14px">{{ $t('product.pro') }}</van-checkbox>
				</div>
				<div class="img"><img :src="item.image" /></div>
				<div class="proinfo">
					<div class="tit">{{ item.name }}</div>
					<div class="price">${{ item.unit_price }}</div>
				</div>
			</div>
			
			
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			keyword: '',
			proid:'',
			catid:'',
			catsid:'',
			pronum:10,
			isChecked:false,
			prolist:[],
			procate:[],
			pagelist:[],
			page:1,
			isLoading: false,
			active:false,
			categories:0,
			prosubcate:[],
			active_sub:false,
			active_num:false,
			totalnum:0
		};
	},
	computed:{
		allcheck:{
			get(){
				//取值
				//every方法，数组中每一项都满足一个条件返回true
				return this.prolist.length && this.prolist.every(item=>item.isChk)
			},
			set(newValue){
				//设置值
				this.prolist.map(item=>item.isChk=newValue)
			}
		},
	},
	methods: {
		selAll(){
			let that=this;
			that.prolist.forEach(item_p => {
				if(that.isChecked){
					item_p.isChk = false;
				}
				else{
					item_p.isChk = true;
				}
			});
			console.log(that.isChecked);
		},
		selectpro(){
			let ids='0';
			this.prolist.forEach(item_p => {
				if(item_p.isChk){
					ids += ',' + item_p.id;
				}
			});
			this.$http({
				method: 'get',
				url: 'productadd',
				data:{
					ids:ids
				}
			}).then(res => {
				console.log(res);
				this.getProductList();
			})
		},
		selcate(item){
			this.categories = item.id;
			this.getProductList();
			this.active = false;
			this.catid = item.name;
			this.getProductSubCat();
		},
		selsubcate(item){
			this.categories = item.id;
			this.getProductList();
			this.active_sub = false;
			this.catsid = item.name;
		},
		selpronum(item){
			this.pronum = item;
			this.active_num = false;
			this.getProductList();
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.$t("reservation.refresh"));
				this.getLotteryList();
				this.isLoading = false;
			}, 200);
		},
		getProductList(){
			this.$http({
				method: 'get',
				url: 'productlist',
				data:{
					page:this.page,
					categories:this.categories,
					pronum:this.pronum
				}
			}).then(res => {
				console.log(res);
				this.prolist = res.data.list;
				// this.prolist.forEach(item_p => {
				// 	item_p.isChk = false;
				// });
				this.totalnum = res.data.count;
			})
		},
		getProductCat(){
			this.$http({
				method: 'get',
				url: 'productcat',
			}).then(res => {
				this.procate = res.data;
			})
		},
		getProductSubCat(){
			this.$http({
				method: 'get',
				url: 'productsubcat',
				data:{id:this.categories}
			}).then(res => {
				this.prosubcate = res.data;
			})
		},
		selpage(pa){
			this.page = pa;
			this.getProductList();
		}
	},
	created() {
		if(!localStorage.getItem('access_token')){
			this.$router.push({path:'/Login'})
		}
		else{
			this.getProductList();
			this.getProductCat();
		}
			// this.getProductList();
			// this.getProductCat();
	}
}

</script>

<style lang='less' scoped>
.banner {
	width: 100%;
}

.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 20px auto 0;
	padding-top: 30px;
	position: relative;
	.prosearch{
		display: flex;
		.catselect{
			width: 100%;
			display: flex;
			justify-content:flex-start;
			.inputfield{
				width:160px;
				border:solid 1px #ccc;
				border-radius: 4px;
			}
		}
	}
	.prolisttit{
		height: 24px;
		background: #000;
		display: flex;
		justify-content: flex-start;
		color: #fff;
		padding: 5px 10px;
		margin: 5px 0;
	}
	.productlist{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		.chk{
			width: 25px;
			padding-left: 10px;
		}
		.img{
			width: 25%;
			img{
				width: 150px;
				height: 150px;
			}
		}
		.proinfo{
			width: 70%;
		}
	}
}
.item{
	padding: 10px 0;
}
.item li{
	line-height: 24px;
	margin: 10px 20px;
	cursor: pointer;
}

.pagebox{
	display: flex;
	padding-top: 10px;
	justify-content: end;
	span a{
		padding: 0 6px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		display: block;
		margin: 0 4px;
		background: #ececec;
		color: #000;
	}
	span.on a{
		background: #c9151e;
		color: #fff;
	}
}
::v-deep .van-pagination__item{
	color: #000;
}
::v-deep .van-pagination__item--active{
	background: #c9151e;
	color: #fff;
}

::v-deep .van-checkbox__label{
	color:#fff;
	font-size: 13px;
}
::v-deep .van-button--normal{
	font-size: 14px;
	width: 88px;
	padding: 0 10px;
	border:none;
	background: #c9151e;
}

::v-deep .van-popup {
	position: absolute;
	top: 46px;
	background: #fff;
	max-height: 274px;
}
</style>
