<template>
	<div class="container page">
		<div class="bg-wrapper">
			<div class="logo"><img src="../images/logo.png" /></div>
			
			<div class="login">
				<div class="wrapper">
					
					<div class="loginForm">
						<div class="title">{{ $t('auth.login') }}</div>
						<div class="inputfield">
							
							<van-field v-model="username" class="input" :placeholder="$t('auth.login_user_tip')">
								<van-icon class="to-icon-arrow-mail" class-prefix="icon"  slot="left-icon" name="yonghu"></van-icon>
							</van-field>
						</div>
						<div class="inputfield">
							<van-field v-model="password" type="password" class="input" :placeholder="$t('auth.login_pwd_tip')">
								<van-icon class="to-icon-arrow-lock" class-prefix="icon"  slot="left-icon" name="yonghu"></van-icon>
								
							</van-field>
						</div>
						
						<van-button class="login-btn" type="primary" size="normal" @click="doLogin()">{{ $t('auth.loginbtn') }}</van-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		/**
		 * 当前输入的值
		 */
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			username: "",
			lang: 'en_us',
			password: this.inputValue,
			passwordType: "password",
			tipshow:false,
			remember:0
		};
	},
	mounted() {
		// localStorage.setItem("lang", 'ms_my');
		this.lang = localStorage.getItem("lang") || 'zh_cn';
	},
	methods: {
		switchPasswordType() {
			this.passwordType =
				this.passwordType === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		toRegister() {
			this.$router.push("Register");
		},
		doLogin() {
			if (
				this.username === "" ||
				this.username === null ||
				this.username === undefined
			) {
				this.$toast(this.$t('auth.login_user_tip'));
				return false;
			}
			if (
				this.password === "" ||
				this.password === null ||
				this.password === undefined
			) {
				this.$toast(this.$t('auth.login_pwd_tip'));
				return false;
			}
			this.$http({
				url: "login",
				method: "post",
				data: {
					account: this.username,
					password: this.password
				}
			}).then((res) => {
				console.log(res);
				if (res.code==1) {
					localStorage.setItem("access_token", res.data.userinfo.token);
					localStorage.setItem("sellerid", res.data.userinfo.id);
					localStorage.setItem("sellername", res.data.userinfo.name);
					localStorage.setItem("selleremail", res.data.userinfo.email);
					localStorage.setItem("selleravatar", res.data.userinfo.avatar);
					localStorage.setItem("sellerphone", res.data.userinfo.phone);
					this.$toast.success(res.msg);
					this.$router.push("Mine");
				} else {
					this.$toast(res.message);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("access_token")) {
			this.$router.push("Mine");
		}
	},
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container{
		background: url(../images/home/index_bg_003.jpg) no-repeat center top;
		background-size: 100% 100%;
	.bg-wrapper{
		width: 1200px;
		margin: 0 auto;
	}
}
.logo{
	padding-top:33px;
}

.login {
	padding-top:110px;
	display: flex;
	justify-content: flex-end;
	.wrapper{
		width: 500px;
		background: #000;
		border-radius: 10px;
	}
}
.webinfo{
	display: flex;
    margin: 30px;
    height: 94px;
	.logo{
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	span{
		width: 40%;
		font-size: 18px;
		margin-left: 10px;
		font-weight: 700;
	}
}
.inputfield{
	margin-top: 20px;
	span{
		font-weight: 700;
	}
}
.to-icon-arrow-mail{
	display: block;
	width: 30px;
	height: 25px;
	background-size: contain;
	margin-top: 8px;
}
.to-icon-arrow-lock{
	display: block;
	width: 30px;
	height: 25px;
	background-size: contain;
	margin-top: 8px;
}

.login .wrapper .loginForm .input {
	padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    overflow: unset;
    height: 50px;
    border-radius: 10px;
}

::v-deep .van-cell::after {
	border: none;
}
::v-deep .van-field__control {
	color:#000;
	text-align: left;
}

::v-deep .van-field__right-icon .van-icon {
	font-size: 22px;
}

::v-deep .van-icon {
	font-size: 16px;
}
::v-deep .to-icon-arrow-mail {
	margin-top: 0;
}
::v-deep .to-icon-arrow-lock {
	margin-top: 0;
}
.login .wrapper .loginForm {
	padding: 30px;
}
.login .wrapper .loginForm .title {
	font-size: 24px;
    font-weight: 700;
    color: #fff;
    padding-bottom: 20px;
    border-bottom: 2px solid #fff;
}

.login .wrapper .loginForm .login-btn {
	margin-top: 30px;
    width: 100%;
    height: 60px;
    color: #fff;
    background: #982123;
    font-size: 16px;
    font-weight: bolder;
	border-radius: 4px;
    border: none;
}
.login .register-text {
	text-align: center;
	width: 100%;
	margin-top: 80px;
	color: #a8d3ff;
}

::v-deep .van-checkbox {
	height: 30px;
}
::v-deep .van-checkbox__icon {
	height: auto;
}


.login .wrapper .loginForm .reset-text {
	margin: 30px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 15px;
}


.login .wrapper .loginForm .register-text span {
	color: #fff;
	font-size: 25px;
	font-weight: 500;
	line-height: 20px;
}


</style>
