<template>
	<div class="home-container">
		<div class="banner">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
				<swiper-slide>
					<img src="../images/banner/1.jpg" />
				</swiper-slide>
				<swiper-slide>
					<img src="../images/banner/2.jpg" />
				</swiper-slide>
				<swiper-slide>
					<img src="../images/banner/3.jpg" />
				</swiper-slide>
			</swiper>
		</div>
		<div class="search">
			<div class="searchimpt">
				<input class="inputcon" v-model="keyword" type="text" :placeholder="$t('home.search_key')" />
				<van-icon name="search" color="#ffffff" size="22" />
			</div>
		</div>
		<div class="content_box">
			<div class="world">
				<div class="con">
					<div class="title">{{ $t("home.tit_1") }}</div>
					<div class="stitle">{{ $t("home.tit_2") }}</div>
					<div class="list">
						<ul>
							<li>
								<img src="../images/home/con1.png" />
								<div>{{ $t("home.con_1") }}</div>
							</li>
							<li>
								<img src="../images/home/con2.png" />
								<div>{{ $t("home.con_2") }}</div>
							</li>
							<li>
								<img src="../images/home/con3.png" />
								<div>{{ $t("home.con_3") }}</div>
							</li>
							<li>
								<img src="../images/home/con4.png" />
								<div>{{ $t("home.con_4") }}</div>
							</li>
							<li>
								<img src="../images/home/con5.png" />
								<div>{{ $t("home.con_5") }}</div>
							</li>
							<li>
								<img src="../images/home/con6.png" />
								<div>{{ $t("home.con_6") }}</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="img"><img src="../images/home/index_earth.png" /></div>
			</div>
		</div>
		<div class="serve_box">
			<div class="serve_con">
				<div class="tit">{{ $t("home.serv_tit_1") }}</div>
				<ul>
					<li>
						<div class="bgimg1"></div>
						<div class="stit">{{ $t("home.serv_tit_2") }}</div>
						<div class="des">{{ $t("home.serv_con_1") }}</div>
					</li>
					<li>
						<div class="bgimg2"></div>
						<div class="stit">{{ $t("home.serv_tit_3") }}</div>
						<div class="des">{{ $t("home.serv_con_2") }}</div>
					</li>
					<li>
						<div class="bgimg3"></div>
						<div class="stit">{{ $t("home.serv_tit_4") }}</div>
						<div class="des">{{ $t("home.serv_con_3") }}</div>
					</li>
					<li>
						<div class="bgimg4"></div>
						<div class="stit">{{ $t("home.serv_tit_5") }}</div>
						<div class="des">{{ $t("home.serv_con_4") }}</div>
					</li>
				</ul>
			</div>
			<div class="map_box">
				<div class="tit">{{ $t("home.serv_tit_6") }}</div>
				<img class="aaa" src="../images/home/index_phonecar.png" />
				<img src="../images/home/index_map.png" style="width: 100%;" />
			</div>
		</div>

		<div class="youshi_box">
			<div class="youshi_con">
				<div class="tit">{{ $t("home.youshi_tit_1") }}</div>
				<ul>
					<li>
						<div class="conimgsz bgimg1"></div>
						<div class="stit">100</div>
						<div class="des">{{ $t("home.youshi_con_1") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg2"></div>
						<div class="stit">50</div>
						<div class="des">{{ $t("home.youshi_con_2") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg3"></div>
						<div class="stit">30</div>
						<div class="des">{{ $t("home.youshi_con_3") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg4"></div>
						<div class="stit">100</div>
						<div class="des">{{ $t("home.youshi_con_4") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg5"></div>
						<div class="stit">99.99</div>
						<div class="des">{{ $t("home.youshi_con_5") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg6"></div>
						<div class="stit">24</div>
						<div class="des">{{ $t("home.youshi_con_6") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg7"></div>
						<div class="stit">48</div>
						<div class="des">{{ $t("home.youshi_con_7") }}</div>
					</li>
					<li>
						<div class="conimgsz bgimg8"></div>
						<div class="stit">99.95</div>
						<div class="des">{{ $t("home.youshi_con_8") }}</div>
					</li>
				</ul>
			</div>
			<div class="subfuw">
				<div class="title">{{ $t("home.youshi_tit_1") }}</div>
				<div class="sublclist">
					<div class="xuxian"></div>
					<div class="lie">
						<div class="rxx"></div>
						<div class="dian"></div>
						<div class="wenz"><span>1</span>
							<div class="wenzzsub">{{ $t("home.youshi_tit_3") }}</div>
						</div>
					</div>
					<div class="lie">
						<div class="wenz">
							<div class="wenzzsub">{{ $t("home.youshi_tit_4") }}</div><span>2</span>
						</div>
						<div class="dian"></div>
						<div class="lxx"></div>
					</div>
					<div class="lie">
						<div class="rxx"></div>
						<div class="dian"></div>
						<div class="wenz"><span>3</span>
							<div class="wenzzsub">{{ $t("home.youshi_tit_5") }}</div>
						</div>
					</div>
					<div class="lie">
						<div class="wenz">
							<div class="wenzzsub">{{ $t("home.youshi_tit_6") }}</div><span>4</span>
						</div>
						<div class="dian"></div>
						<div class="lxx"></div>
					</div>
					<div class="lie">
						<div class="rxx"></div>
						<div class="dian"></div>
						<div class="wenz"><span>5</span>
							<div class="wenzzsub">{{ $t("home.youshi_tit_7") }}</div>
						</div>
					</div>
					<div class="lie">
						<div class="wenz">
							<div class="wenzzsub">{{ $t("home.youshi_tit_8") }}</div><span>6</span>
						</div>
						<div class="dian"></div>
						<div class="lxx"></div>
					</div>
					<div class="lie">
						<div class="rxx"></div>
						<div class="dian"></div>
						<div class="wenz"><span>7</span>
							<div class="wenzzsub">{{ $t("home.youshi_tit_9") }}</div>
						</div>
					</div>
					<div class="lie">
						<div class="wenz">
							<div class="wenzzsub">{{ $t("home.youshi_tit_10") }}</div><span>8</span>
						</div>
						<div class="dian"></div>
						<div class="lxx"></div>
					</div>
				</div>
				<div class="sublcimg" style="padding:50px 0;text-align: center;"><img src="../images/home/index_car.png" alt="" style="width: 1000px;"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			keyword: ''
		};
	},
	methods: {

	},
	created() {

	}
}

</script>

<style lang='less' scoped>
.banner {
	width: 100%;
	height: 554px;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;

		img {
			width: 100%;
			height: 100%;
		}
	}
}

.search {
	padding: 15px 0;
	position: relative;
	align-items: center;
	background-color: #000102;

	.searchimpt {
		background-color: #982123;
		margin: 0 auto;
		max-width: 40%;
		display: flex;
		height: 40px;
		border-radius: 20px;
		border: 2px solid #fff;
		overflow: hidden;
		padding-right: 18px;

		.inputcon {
			width: 100%;
			height: 36px;
			line-height: 36px;
			border: unset;
			outline: none;
			border-radius: 5px;
			background-color: #000102;
			color: #fff;
			text-indent: 18px;
			font-size: 18px;
		}

		.van-icon {
			font-size: 20px;
			padding: 0;
			margin: 0;
			color: #fff;
			line-height: 35px;
			margin-left: 5px;
		}
	}
}

.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 20px auto 0;

	.world {
		display: flex;

		>div {
			width: 50%;
		}

		.con {
			.title {
				font-size: 30px;
				font-weight: 700;
				text-align: left;
				padding-top: 50px;
				position: relative;
				width: 100%;
			}

			.title:after {
				height: 5px;
				width: 100px;
				background-color: #982123;
				content: "";
				position: absolute;
				bottom: -10px;
				left: 0;
			}

			.stitle {
				font-size: 22px;
				padding: 30px 0;
				color: #000;
				text-align: left;
				width: 100%;
			}
		}

		.img {
			width: 600px;

			img {
				width: 600px;
			}
		}

		.list {
			width: 100%;

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					width: 33.3%;
					display: flex;
					align-items: center;
					justify-content: center;
					justify-items: center;
					flex-wrap: wrap;
					padding: 30px 0;
					color: #000;
					font-weight: 700;
					font-size: 24px;

					img {
						width: 110px;
					}

					div {
						text-align: center;
					}
				}
			}
		}
	}

}

.serve_box {
	background: url(../images/home/index_bg_001.jpg) no-repeat 50%;

	.serve_con {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 50px;

		.tit {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.tit:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		ul {
			display: flex;
			justify-content: space-around;
			padding-top: 100px;
			padding-bottom: 40px;

			li {
				width: 20%;
				padding: 20px 20px;
				margin: 0 auto;
				background-color: #fff;
				border-radius: 15px;
				box-shadow: 1px 1px 3px #ccc;
				margin-bottom: 20px;
				position: relative;

				.bgimg1 {
					background: url(../images/home/conn1_12.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_22.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_32.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_42.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					position: relative;
					font-size: 24px;
					padding: 1rem 0;
					color: #000;
					text-align: left;
					font-weight: 700;
				}

				.stit:after {
					height: 3px;
					width: 60px;
					background-color: #c9151e;
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					margin-left: 0;
				}

				.des {
					padding: 1rem 0;
					font-size: 16px;
					color: #000;
					text-align: left;
				}
			}

			li:hover {
				background-color: #c9151e;
				color: #fff;

				.bgimg1 {
					background: url(../images/home/conn1_11.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_21.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_31.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_41.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					color: #fff;
				}

				.stit:after {
					background-color: #fff;

				}

				.des {
					color: #fff;
				}
			}
		}
	}

	.map_box {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 80px;
		position: relative;
		
		.tit {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
			margin-bottom: 120px;
		}

		.tit:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		.aaa {
			width: 32%;
			position: absolute;
			right: -20px;
			top: -25px;
		}
	}
}
.youshi_box{
	background: url(../images/home/index_bg_002.jpg) no-repeat 50%;
	.youshi_con{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 50px;
		.tit {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.tit:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}
		ul{
			padding: 80px 0 20px;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			justify-content: space-around;
			li{
				width: 21%;
				padding: 10px 20px;
				margin: 0 auto 50px;
				background-color: #fff;
				border-radius: 15px;
				box-shadow: 1px 1px 3px #ccc;
				position: relative;
				.conimgsz{
					width: 100px;
					height: 100px;
					padding: 10px;
					border: 2px solid #000;
					border-radius: 50%;
					display: block;
					margin: 0 auto;
					background-size: 90% 90%;
					margin-top: 3rem;
					position: absolute;
					right: 20px;
					top: -75px;
				}
				.bgimg1{
					background: url(../images/home/ico1.png) #fff no-repeat 50%;
				}
				.bgimg2{
					background: url(../images/home/ico2.png) #fff no-repeat 50%;
				}
				.bgimg3{
					background: url(../images/home/ico3.png) #fff no-repeat 50%;
				}
				.bgimg4{
					background: url(../images/home/ico4.png) #fff no-repeat 50%;
				}
				.bgimg5{
					background: url(../images/home/ico5.png) #fff no-repeat 50%;
				}
				.bgimg6{
					background: url(../images/home/ico6.png) #fff no-repeat 50%;
				}
				.bgimg7{
					background: url(../images/home/ico7.png) #fff no-repeat 50%;
				}
				.bgimg8{
					background: url(../images/home/ico8.png) #fff no-repeat 50%;
				}
				.stit{
					color: #c8151f;
					font-weight: 700;
					font-size: 35px;
					text-align: left;
					padding-top: 12px;
				}
				.des{
					padding: 1rem 0;
					font-size: 20px;
					color: #000;
					text-align: left;
				}
			}
		}
	}
	.subfuw{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		padding-top: 50px;
		.title{
			font-size: 40px;
			color: #fff;
			position: relative;
			text-align: center;
			font-weight: 700;
		}
		.title:after{
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}
		.sublclist{
			padding-top: 50px;
			display: flex;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			.xuxian{
				border-right: 3px dashed #fff;
				position: absolute;
				left: calc(50% - 1.5px);
				height: 100%;
			}
			.lie{
				position: relative;
				display: flex;
				justify-content: center;
				margin: 0 20px;
				.rxx{
					width: 300px;
				}
				.lxx{
					width: 300px;
				}
				.dian{
					width: 20px;
					height: 20px;
					background-color: #fff;
					margin: 0 20px;
					border-radius: 50%;
					margin-top: 20px;
				}
				.wenz{
					position: relative;
					text-align: center;
					background-color: #fff;
					border-radius: 30px;
					display: flex;
					justify-content: flex-start;
					height: 60px;
					line-height: 60px;
					font-size: 20px;
					font-weight: 700;
					width:300px;
					span{
						border-radius: 30px;
						display: block;
						width: 60px;
						height: 60px;
						font-size: 25px;
						font-weight: 700;
						background-color: #c8151f;
						text-align: center;
						line-height: 60px;
						color: #fff;
					}
					.wenzzsub{
						width:240px;
					}
				}
				.wenz::after{
					position: absolute;
					content: "";
					display: block;
					top: 20px;
					left: -8px;
					width: 0;
					height: 0;
					border-top: 10px solid transparent;
					border-right: 10px solid #c8151f;
					border-bottom: 10px solid transparent;
					border-left: none;
				}
			}
			.lie:nth-child(odd){
				.wenz{
					justify-content: flex-end;
				}
				.wenz:after{
					position: absolute;
					content: "";
					display: block;
					top: 20px;
					right: -8px;
					left: auto;
					width: 0;
					height: 0;
					border-top: 10px solid transparent;
					border-right: none;
					border-bottom: 10px solid transparent;
					border-left: 10px solid #c8151f;
				}
			}
		}
	}
}

</style>
