<template>
	<div id="app">
		<Header />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import Footer from './common/Footer'
import Header from './common/Header'
export default {
	name: '',
	components: {
		Footer,
		Header
	},
	data() {
		return {
			status: 0
		};
	},
	methods: {
		getBaseInfo() {
			this.$http({
				method: 'get',
				url: 'webconfig'
			}).then(res => {
				this.$store.commit('setBaseInfoValue', res.data);
			})
		}

	},
	created() {
		this.getBaseInfo();
	}
}
</script>

<style>

body .van-toast {
	font-size: 16px;
	padding: 30px;
	line-height: 50px;
}

body .van-toast .van-toast__icon {
	font-size: 20px;
}

*,
:after,
:before {
	box-sizing: border-box;
}
</style>
