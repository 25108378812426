<template>
	<div class="home-container">
		<div class="banner">
			<img src="../images/banner/banner_02.jpg" style="width: 100%;" />
		</div>
		<div class="content_box">
			<div class="world">
				<div class="titttt">{{ $t("daifa.serv_qutit_1") }}</div>
				<div class="con">
					<div class="list">
						<ul>
							<li>
								<img src="../images/home/d1.png" />
								<div>{{ $t("daifa.daifa_con_1") }}</div>
							</li>
							<li>
								<img src="../images/home/d2.png" />
								<div>{{ $t("daifa.daifa_con_2") }}</div>
							</li>
							<li>
								<img src="../images/home/d3.png" />
								<div>{{ $t("daifa.daifa_con_3") }}</div>
							</li>
						</ul>
					</div>
					<div class="img"><img src="../images/home/index3_car.png" /></div>
				</div>
			</div>
		</div>
		<div class="serve_box">
			<div class="serve_con">
				<div class="tit">{{ $t("daifa.serv_qutit_2") }}</div>
				<ul>
					<li>
						<div class="bgimg1"></div>
						<div class="stit">{{ $t("daifa.daifa_serv_tit_1") }}</div>
						<div class="des">{{ $t("daifa.daifa_serv_con_1") }}</div>
					</li>
					<li>
						<div class="bgimg2"></div>
						<div class="stit">{{ $t("daifa.daifa_serv_tit_2") }}</div>
						<div class="des">{{ $t("daifa.daifa_serv_con_2") }}</div>
					</li>
					<li>
						<div class="bgimg3"></div>
						<div class="stit">{{ $t("daifa.daifa_serv_tit_3") }}</div>
						<div class="des">{{ $t("daifa.daifa_serv_con_3") }}</div>
					</li>
					<li>
						<div class="bgimg4"></div>
						<div class="stit">{{ $t("daifa.daifa_serv_tit_4") }}</div>
						<div class="des">{{ $t("daifa.daifa_serv_con_4") }}</div>
					</li>
				</ul>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			keyword: ''
		};
	},
	methods: {

	},
	created() {

	}
}

</script>

<style lang='less' scoped>
.banner {
	width: 100%;
}

.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 20px auto 0;

	padding-top: 50px;

	.world {

		.titttt {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.titttt:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		.con {
			display: flex;
			margin-top: 50px;
			>div {
				width: 50%;
			}
			
			.img {
				width: 600px;

				img {
					width: 600px;
				}
			}

			.list {
				width: 100%;

				ul {
					li {
						display: flex;
						justify-content: flex-start;
						padding: 10px 30px;
						box-shadow: 1px 1px 5px #666;
						border-radius: 10px;
						margin: 20px 0;
						align-items: center;
						img {
							width: 110px;
							margin-right: 30px;
						}

						div {
							font-size: 22px;
							font-weight: 700;
							text-align: center;
						}
					}
				}
			}
		}

	}

}

.serve_box {
	.serve_con {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
		padding-top: 50px;

		.tit {
			font-size: 40px;
			color: #000;
			position: relative;
			text-align: center;
			font-weight: 700;
		}

		.tit:after {
			height: 5px;
			width: 50px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -25px;
		}

		ul {
			display: flex;
			justify-content: space-around;
			padding-top: 100px;
			padding-bottom: 40px;

			li {
				width: 20%;
				padding: 20px 20px;
				margin: 0 auto;
				background-color: #fff;
				border-radius: 15px;
				box-shadow: 1px 1px 3px #ccc;
				margin-bottom: 20px;
				position: relative;

				.bgimg1 {
					background: url(../images/home/conn1_12.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_22.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_32.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_42.png) no-repeat #c9151e 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					position: relative;
					font-size: 24px;
					padding: 1rem 0;
					color: #000;
					text-align: left;
					font-weight: 700;
				}

				.stit:after {
					height: 3px;
					width: 60px;
					background-color: #c9151e;
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					margin-left: 0;
				}

				.des {
					padding: 1rem 0;
					font-size: 16px;
					color: #000;
					text-align: left;
				}
			}

			li:hover {
				background-color: #c9151e;
				color: #fff;

				.bgimg1 {
					background: url(../images/home/conn1_11.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg2 {
					background: url(../images/home/conn1_21.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg3 {
					background: url(../images/home/conn1_31.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.bgimg4 {
					background: url(../images/home/conn1_41.png) no-repeat #fff 50%;
					width: 110px;
					height: 110px;
					border-radius: 50%;
				}

				.stit {
					color: #fff;
				}

				.stit:after {
					background-color: #fff;

				}

				.des {
					color: #fff;
				}
			}
		}
	}
}

</style>
