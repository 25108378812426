import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Intro from '../pages/home/intro.vue'/* 海外仓介绍 */
import Daifa from '../pages/home/daifa.vue'/* 一件代发 */
import News from '../pages/home/news.vue'/* 行业新闻 */
import About from '../pages/home/about.vue'/* 关于我们 */
import Product from '../pages/mine/product.vue'/* 商品仓库 */
import Mine from '../pages/mine/index.vue'/* 用户中心 */
import Login from '../pages/login/index.vue'/* 用户中心 */



Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:'首页'}},
    {path:'/Home',name:'Home',component:Home,meta:{title:'首页'}},
    {path:'/Intro',name:'Intro',component:Intro,meta:{title:'海外仓介绍'}},
    {path:'/Daifa',name:'Daifa',component:Daifa,meta:{title:'一件代发'}},
    {path:'/News',name:'News',component:News,meta:{title:'行业新闻'}},
    {path:'/About',name:'About',component:About,meta:{title:'关于我们'}},
    {path:'/Product',name:'Product',component:Product,meta:{title:'商品仓库'}},
    {path:'/Mine',name:'Mine',component:Mine,meta:{title:'用户中心'}},
    {path:'/Login',name:'Login',component:Login,meta:{title:'用户登录'}},


];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router