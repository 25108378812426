<template>
	<div class="home-container">
		<div class="banner">
			<img src="../images/banner/banner_01.jpg" style="width: 100%;" />
		</div>
		<div class="content_box">
			<swiper class="banner_swiper" :options="bannerSwiperOption">
				<swiper-slide v-for="(item,ind) in datalist" :key="ind">
					<div class="con_box">
						<div class="img"><img :src="item.img" /></div>
						<div class="con_right">
							<div class="tit">{{ item.title }}</div>
							<div class="des">{{ item.intro }}</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			bannerSwiperOption: {
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed: 2500,
				autoplay: true,
			},
			keyword: '',
			datalist:[]
		};
	},
	methods: {
		showIntro(){
			for(let i=1;i<=18;i++){
				let arr={
					'img':require('../images/home/' + i + '.jpg'),
					'title':this.$t('intro.ctitle' + i),
					'intro':this.$t('intro.cintro' + i)
				}
				this.datalist.push(arr);
			}
		}
	},
	created() {
		this.showIntro();
	}
}

</script>

<style lang='less' scoped>
.banner {
	width: 100%;
}

.banner_swiper {
	height: 100%;
	width: 100%;

	.swiper-slide {
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		background-color: #ffffff;
		background-position: center;
		background-size: cover;
		color: #ffffff;

		img {
			width: 100%;
			height: 100%;
		}
	}
}


.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}
.con_box{
	width: 100%;
	display: flex;
    flex-wrap: wrap;
    background-color: #000102;
    border-radius: 10px;
    overflow: hidden;
	margin: 110px 55px;
	.img{
		width: 60%;
		img{
			width: 100%;
		}
	}
	.con_right{
		width: 34%;
		margin-left: 3%;
		margin-right: 3%;
		text-align: left;
		.tit{
			font-size: 26px;
			position: relative;
			font-weight: 700;
			color: #fff;
			padding-top: 30px;
			margin-bottom: 30px;
		}
		.tit::after{
			height: 5px;
			width: 100px;
			background-color: #982123;
			content: "";
			position: absolute;
			bottom: -10px;
			left: 0;
			margin-left: 0;
		}
		.des{
			color: #fff;
			font-size: 20px;
			font-weight: normal;
		}
	}
}

</style>
